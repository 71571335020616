import { MouseEvent, useState } from 'react';
import useCustomContext from 'hooks/useCustomContext';
import menuItems, { MenuItem } from 'menu-items';
import { useLocation } from 'react-router';
import { Box, Button, CardMedia, Link, Popover, Stack, useMediaQuery } from '@mui/material';

function Header() {
  const { pathname } = useLocation();
  const upMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const { isLightBg } = useCustomContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = Boolean(anchorEl);

  const isActive = (menuItem: MenuItem) => {
    if (menuItem.children) {
      const isActiveChild = menuItem.children.some((child) => child.href === pathname);
      if (isActiveChild) return true;
    }

    return menuItem.href === pathname;
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component="nav"
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: 1,
        py: '45px',
        px: '165px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 50,
        [theme.breakpoints.down('xl')]: {
          py: '33.5px',
          px: '120px'
        },
        [theme.breakpoints.down('lg')]: {
          py: '23.5px',
          px: '85px'
        },
        [theme.breakpoints.down('md')]: {
          py: '14.5px',
          px: '24px'
        }
      })}
    >
      <Box
        sx={{
          display: 'inline-block',
          cursor: 'pointer'
        }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <CardMedia
          component="img"
          src={isLightBg ? '/logo_blue.png' : '/logo.png'}
          alt="SHEKR Logo"
          sx={(theme) => ({
            width: '119px',
            height: '30.013px',
            [theme.breakpoints.down('xl')]: { width: '91.195px', height: '23px' },
            [theme.breakpoints.down('lg')]: { width: '65px', height: '17px' },
            [theme.breakpoints.down('md')]: { width: '67.405px', height: '17px' }
          })}
        />
      </Box>
      {upMd ? (
        <Stack direction="row" spacing={{ md: 3.125, lg: 4.5, xl: 6 }}>
          {menuItems.items.map((item) => (
            <Link
              key={item.id}
              href={item.href}
              sx={(theme) => ({
                position: 'relative',
                color: isLightBg ? '#05444C' : '#FFF',
                fontSize: 20,
                lineHeight: 'normal',
                textDecoration: 'none',
                '&:hover': {
                  '& span': {
                    transform: 'scaleX(1)'
                  }
                },
                [theme.breakpoints.down('xl')]: {
                  fontSize: 18
                },
                [theme.breakpoints.down('lg')]: {
                  fontSize: 14
                }
              })}
            >
              {item.label}
              <Box
                component="span"
                sx={(theme) => ({
                  position: 'absolute',
                  left: 0,
                  bottom: -10,
                  height: '4px',
                  width: 1,
                  bgcolor: isLightBg ? '#05444C' : '#FFF',
                  borderRadius: '6px',
                  transform: 'scaleX(0)',
                  transformOrigin: 'left',
                  transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                  ...(isActive(item) && {
                    transform: 'scaleX(1)'
                  }),
                  [theme.breakpoints.down('xl')]: {
                    bottom: -8
                  },
                  [theme.breakpoints.down('lg')]: {
                    bottom: -5,
                    height: '3px'
                  }
                })}
              ></Box>
            </Link>
          ))}
        </Stack>
      ) : (
        <Button disableRipple sx={{ minWidth: 'unset', padding: 0, '&:hover': { bgcolor: 'unset' } }} onClick={handleClick}>
          <Box sx={{ width: '22.369px', height: '17.001px', position: 'relative' }}>
            <Box
              component="span"
              sx={{
                position: 'absolute',
                ...(isOpen ? { top: '50%', transform: 'translateY(-50%) rotate(-45deg)', width: 1 } : { top: 0, width: '80%' }),
                right: 0,
                height: '3px',
                transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                borderRadius: '4px',
                bgcolor: isLightBg ? '#2E97A7' : '#FFF'
              }}
            ></Box>
            <Box
              component="span"
              sx={{
                ...(isOpen && { display: 'none' }),
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0,
                width: '80%',
                height: '3px',
                borderRadius: '4px',
                bgcolor: isLightBg ? '#2E97A7' : '#FFF'
              }}
            ></Box>
            <Box
              component="span"
              sx={{
                position: 'absolute',
                ...(isOpen ? { top: '50%', transform: 'translateY(-50%) rotate(45deg)', width: 1 } : { bottom: 0, width: '80%' }),
                right: 0,
                height: '3px',
                transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
                borderRadius: '4px',
                bgcolor: isLightBg ? '#2E97A7' : '#FFF'
              }}
            ></Box>
          </Box>
        </Button>
      )}
      <Popover
        id={isOpen ? 'nav-menu' : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        slotProps={{
          paper: {
            sx: {
              width: '220px',
              borderRadius: '20px',
              bgcolor: '#151522',
              color: '#FFF',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              p: '29px'
            }
          }
        }}
        sx={{ mt: '19px' }}
      >
        {menuItems.items.map((item, index, self) => (
          <Link
            key={item.id}
            href={item.href}
            onClick={handleClose}
            sx={{
              display: 'block',
              position: 'relative',
              color: '#FFF',
              fontSize: 16,
              lineHeight: '22px',
              letterSpacing: '0.5px',
              textDecoration: 'none',
              ...(index < self.length - 1 && { mb: 3 }),
              '&:hover': {
                '& span': {
                  transform: 'scaleX(1)'
                }
              }
            }}
          >
            {item.label}
            <Box
              component="span"
              sx={{
                position: 'absolute',
                left: 0,
                bottom: -3,
                height: '3px',
                width: 1,
                bgcolor: '#FFF',
                borderRadius: '6px',
                transform: 'scaleX(0)',
                transformOrigin: 'left',
                transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1)',
                ...(isActive(item) && {
                  transform: 'scaleX(1)'
                })
              }}
            ></Box>
          </Link>
        ))}
      </Popover>
    </Box>
  );
}

export default Header;
